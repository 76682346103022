import React from "react";
import { Link } from "gatsby";
import {
  Container,
  Segment,
  Grid,
  Header,
  Button,
  Icon,
  Divider,
  Menu
} from "semantic-ui-react";

import footerStyles from "./footer.module.css";

const SiteFooter = ({ siteTitle }) => {
  return (
    <Segment inverted vertical className={footerStyles.footer}>
      <Container>
        <Grid inverted stackable>
          <Grid.Row>
            <Grid.Column
              computer={4}
              tablet={8}
              className={footerStyles.column}
            >
              <Header inverted as="h2" content="Our Lessons" />
              <Menu text vertical inverted>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/parents/"
                    className={footerStyles.menuItem}
                  >
                    Primary School Rock Bands
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/secondary/students-and-parents/"
                    className={footerStyles.menuItem}
                  >
                    Secondary School Rock Bands
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/parents/schoolholidaycamps/"
                    className={footerStyles.menuItem}
                  >
                    Rock Band Holiday Camps
                  </a>
                </Menu.Item>
              </Menu>
            </Grid.Column>

            <Grid.Column
              computer={3}
              tablet={8}
              className={footerStyles.column}
            >
              <Header inverted as="h2" content="About" />
              <Menu text vertical inverted>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/about-us-experience/"
                    className={footerStyles.menuItem}
                  >
                    About
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/careers/"
                    className={footerStyles.menuItem}
                  >
                    Careers
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/contact/"
                    className={footerStyles.menuItem}
                  >
                    Contact
                  </a>
                </Menu.Item>
              </Menu>
            </Grid.Column>

            <Grid.Column
              computer={9}
              tablet={16}
              className={footerStyles.column}
            >
              <Header as="h2" inverted>
                Connect with us
              </Header>
              <Button
                as="a"
                color="facebook"
                href="https://www.facebook.com/rocksteadymusicschool"
                icon
                target="_blank"
              >
                <Icon name="facebook" /> Facebook
              </Button>{" "}
              <Button
                as="a"
                color="twitter"
                href="https://twitter.com/rsmusicschool"
                icon
                target="_blank"
              >
                <Icon name="twitter" /> Twitter
              </Button>{" "}
              <Button
                as="a"
                color="instagram"
                href="https://www.instagram.com/rocksteadymusicschool/"
                icon
                target="_blank"
              >
                <Icon name="instagram" /> Instagram
              </Button>
              <Divider />
              <p>
                Website &copy; {new Date().getFullYear()}{" "}
                <em>Rocksteady Music School</em>, Liphook, Hampshire. All Rights
                Reserved.
              </p>
              <Menu text stackable inverted>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/privacy-policy/"
                    className={footerStyles.menuItem}
                  >
                    Privacy Policy
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/privacy-notices/"
                    className={footerStyles.menuItem}
                  >
                    Privacy Notices
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/cookie-policy/"
                    className={footerStyles.menuItem}
                  >
                    Cookie Policy
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href="https://www.rocksteadymusicschool.com/terms-conditions/"
                    className={footerStyles.menuItem}
                  >
                    Terms &amp; Conditions
                  </a>
                </Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default SiteFooter;
